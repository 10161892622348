import { EventEmitter } from 'eventemitter3';
import log from '../utils/logger.utils';
import { MqttEvents } from '../types/events.type';
import { ConfigService } from './config.service';
import mqtt, { MqttClient, IClientOptions } from 'mqtt';
import { MqttMessageAction } from '../types/mqtt.type';

// MQTT options for reconnection
const options: IClientOptions = {
    reconnectPeriod: 1000, // Reconnect after 1 second if disconnected
    connectTimeout: 4000,  // Timeout for initial connection
    keepalive: 60,         // Send a ping every 60 seconds to the server
};

export class MqttService extends EventEmitter {
    private static instance: MqttService;
    private client: MqttClient | null = null;
    private logger: log.Logger;
    private config: ConfigService;

    // Constructor
    private constructor() {
        super();
        this.logger = log.getLogger('MqttService');
        this.config = ConfigService.getInstance();
        this.connect(); // Start MQTT connection on initialization
    }

    public static getInstance(): MqttService {
        if (!MqttService.instance) {
            MqttService.instance = new MqttService();
        }
        return MqttService.instance;
    }

    // Establish MQTT connection with reconnection support
    private connect(): void {
        const mqttUrl = this.config.mqttUrl; // Static MQTT broker URL from config
        this.logger.info('Connecting to MQTT broker:', mqttUrl);

        options.username = this.config.mqttUsername; // Set MQTT username
        options.password = this.config.mqttPassword; // Set MQTT password

        // Create MQTT client instance
        this.client = mqtt.connect(mqttUrl, options);

        // Handle successful connection
        this.client.on('connect', () => {
            this.logger.info('MQTT connected');
        });

        // Handle incoming MQTT messages
        this.client.on('message', (topic, message) => {
            this.handleIncomingMessage(topic, message.toString());
        });

        // Handle connection close and reconnection attempts
        this.client.on('close', () => {
            this.logger.warn('MQTT connection closed');
        });

        // Handle MQTT errors
        this.client.on('error', (error) => {
            this.logger.error('MQTT error:', error);
        });

        this.subscribe('displays/' + this.config.studioId + '/action'); // Subscribe to all dohiit topics
    }

    // Subscribe to an MQTT topic
    public subscribe(topic: string): void {
        if (!this.client) {
            this.logger.error('Cannot subscribe, MQTT client is not connected.');
            return;
        }
        this.client.subscribe(topic, (error) => {
            if (error) {
                this.logger.error(`Error subscribing to topic "${topic}":`, error);
            } else {
                this.logger.info(`Subscribed to topic "${topic}"`);
            }
        });
    }

    // Publish a message to an MQTT topic
    public publish(topic: string, payload: any): void {
        if (!this.client) {
            this.logger.error('Cannot publish, MQTT client is not connected.');
            return;
        }
        const message = JSON.stringify(payload);
        this.client.publish(topic, message, (error) => {
            if (error) {
                this.logger.error(`Error publishing to topic "${topic}":`, error);
            } else {
                this.logger.info(`Published message to topic "${topic}":`, message);
            }
        });
    }

    // Handle incoming MQTT messages
    private handleIncomingMessage(topic: string, data: any): void {
        try {
            const message: MqttMessageAction = JSON.parse(data) as MqttMessageAction;
            this.logger.debug('Received MQTT message:', message);
            this.emit(MqttEvents.onMessage, topic, message); // Emit event for the core class
        } catch (error) {
            this.logger.error('Error parsing MQTT message:', error);
        }
    }

}