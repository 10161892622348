import React from "react";
import { Goal } from "../Goal/Goal";
import "./style.css";
import { TimerScreenData } from "../../types/screen.type";
import { TimerControl } from "../../types/timer.type";
import { ProgressCircle } from "../ProgressCircle";

interface NextClassStartingProps {
  timerScreenData: TimerScreenData;
  timerControlRef?: React.RefObject<TimerControl>;
}


export const HorNextClass = (): JSX.Element => {
  return (
    <div className="next-class-starting">
      <div className="container-wrapper">
        <div className="container">
          <div className="content">
            <div className="title-wrapper">
              <div className="title">
                <Goal className="goal-instance" />
                <div className="text">Próxima aula</div>
              </div>
            </div>
            <ProgressCircle initialTotalTime={120} />
            <div className="PT-container">
              <div className="icon">
                <img className="rectangle" alt="Rectangle" src="rectangle-31.png" />
              </div>
              <div className="text-2">
                <div className="text-3">Jane Cooper</div>
                <div className="text-4">Studio Manager</div>
              </div>
            </div>
          </div>
          <div className="logo-workout">
            <div className="overlap">
              <div className="logo">
                <div className="lgo-wrapper">
                  <img className="lgo" alt="Lgo" src="lgo.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
