import { ClassSchedule } from "../types/class.type";
import { ExerciseTask } from "../types/exercise.type";
import { DateTimeUtils } from "../utils/datetime.utils";
import log from "../utils/logger.utils";

export class ExerciseService {
    private logger: log.Logger;
    private classSchedule: ClassSchedule;
    private tasks: ExerciseTask[];
    private onExerciceComplete: (classSchedule: ClassSchedule) => void;
    private onTask?: (classSchedule: ClassSchedule, task: ExerciseTask) => void;
    private currentIndex: number;
    private remainingTime: number; // in milliseconds
    private timer: NodeJS.Timeout | null;
    private exercisesTaskDelay: number = 1000; // in milliseconds
    private isFirstRun = true;
    private taskStartTime: number = 0;
    private isTaskRunning: boolean = false;
    private currentTask: ExerciseTask | null = null;

    constructor(
        classSchedule: ClassSchedule,
        exercisesTaskDelay?: number,
        onExerciceComplete?: (classSchedule: ClassSchedule) => void,
        onTask?: (classSchedule: ClassSchedule, task: ExerciseTask) => void
    ) {
        this.logger = log.getLogger("ExerciseService");
        this.classSchedule = classSchedule;
        this.onExerciceComplete = onExerciceComplete || (() => {});
        this.onTask = onTask;
        this.currentIndex = 0;
        this.remainingTime = 0;
        this.timer = null;
        this.tasks = this.exerciseTasks(classSchedule);
        this.exercisesTaskDelay = exercisesTaskDelay || this.exercisesTaskDelay;
    }

    // Start or resume the emission process
    play() {
        if (this.timer) {
            // Already running
            return;
        }

        if (this.remainingTime > 0) {
            this.taskStartTime = Date.now();

            if (this.isTaskRunning) {
                // Resume current task
                this.timer = setTimeout(() => {
                    // Task ends
                    this.isTaskRunning = false;
                    this.remainingTime = 0;
                    this.currentIndex++;

                    if (this.currentIndex < this.tasks.length) {
                        this.scheduleNextTask();
                    } else {
                        if (this.onExerciceComplete) {
                            this.onExerciceComplete(this.classSchedule);
                        }
                    }
                }, this.remainingTime);
            } else {
                // Resume delay before task starts
                this.timer = setTimeout(() => {
                    this.startTask(this.currentTask!);
                }, this.remainingTime);
            }
        } else {
            // Start next task
            this.scheduleNextTask();
        }
    }

    // Pause the emission process
    pause() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;

            const elapsedTime = Date.now() - this.taskStartTime;

            if (this.isTaskRunning) {
                const taskDuration = this.currentTask ? this.currentTask.time * 1000 : 0;
                this.remainingTime = taskDuration - elapsedTime;
            } else {
                // Before task starts
                this.remainingTime -= elapsedTime;
            }
        }
    }

    // Restart the emission process from the beginning
    restart() {
        this.pause();
        this.currentIndex = 0;
        this.remainingTime = 0;
        this.isFirstRun = true;
        this.play();
    }

    // Stop the emission process
    stop() {
        this.pause();
        this.currentIndex = 0;
        this.remainingTime = 0;
        this.isFirstRun = true;
    }

    private scheduleNextTask() {
        if (this.currentIndex >= this.tasks.length) {
            return;
        }

        const currentTask = this.tasks[this.currentIndex];
        this.currentTask = currentTask;
        this.isTaskRunning = false;

        // Calculate the delay before starting the task
        const delay = this.isFirstRun ? 0 : this.exercisesTaskDelay;

        this.taskStartTime = Date.now();
        this.remainingTime = delay;

        if (delay === 0) {
            this.startTask(currentTask);
        } else {
            this.timer = setTimeout(() => {
                this.startTask(currentTask);
            }, delay);
        }

        this.isFirstRun = false;
    }

    private startTask(currentTask: ExerciseTask) {
        this.isTaskRunning = true;
        this.taskStartTime = Date.now();
        this.remainingTime = currentTask.time * 1000; // in milliseconds

        if (this.onTask) {
            this.onTask(this.classSchedule, currentTask);
        }

        this.timer = setTimeout(() => {
            // Task ends
            this.isTaskRunning = false;
            this.remainingTime = 0;
            this.currentIndex++;

            if (this.currentIndex < this.tasks.length) {
                this.scheduleNextTask();
            } else {
                if (this.onExerciceComplete) {
                    this.onExerciceComplete(this.classSchedule);
                }
            }
        }, this.remainingTime);
    }

    private exerciseTasks(schedule: ClassSchedule): ExerciseTask[] {
        const tasks: ExerciseTask[] = [];
        if (!schedule) {
            return tasks;
        }

        const workout = schedule.workout;
        const hydration_type = workout.hydration_type;

        for (let i = 0; i < schedule.workout.movement.pods; i++) {
            const currentPod = i + 1;
            workout.pod_config.forEach((pod) => {
                pod.laps.forEach((lap, lapIndex) => {
                    const currentLap = lapIndex + 1;
                    const remainingLaps = pod.laps.length - currentLap;
                    workout.exercises.forEach((exercise) => {
                        for (let set = 1; set <= lap.sets; set++) {
                            const currentSet = set;
                            const remainingSets = lap.sets - set;
                            const currentStation = workout.exercises.indexOf(exercise) + 1;

                            // Create work
                            tasks.push({
                                workout_id: workout.id,
                                stations: workout.exercises.length,
                                current_station: currentStation,
                                pod: currentPod,
                                current_lap: currentLap,
                                remaining_laps: remainingLaps,
                                type: "work",
                                time: lap.work,
                                current_set: currentSet,
                                remaining_sets: remainingSets,
                            });

                            if (!pod.is_amrap) {
                                // Create rest
                                tasks.push({
                                    workout_id: workout.id,
                                    stations: workout.exercises.length,
                                    current_station: currentStation,
                                    pod: currentPod,
                                    current_lap: currentLap,
                                    remaining_laps: remainingLaps,
                                    type: "rest",
                                    time: lap.rest,
                                    current_set: currentSet,
                                    remaining_sets: remainingSets,
                                });
                            }
                        }
                    });

                    if (hydration_type === "lap" && workout.hydration_break > 0) {
                        tasks.push({
                            workout_id: workout.id,
                            stations: workout.exercises.length,
                            current_station: 0,
                            pod: currentPod,
                            current_lap: currentLap,
                            remaining_laps: remainingLaps,
                            type: "hydrate",
                            time: workout.hydration_break,
                            current_set: 0,
                            remaining_sets: 0,
                        });
                    }
                });

                if (hydration_type === "pod" && workout.hydration_break > 0) {
                    tasks.push({
                        workout_id: workout.id,
                        stations: workout.exercises.length,
                        current_station: 0,
                        pod: currentPod,
                        current_lap: 0,
                        remaining_laps: 0,
                        type: "hydrate",
                        time: workout.hydration_break,
                        current_set: 0,
                        remaining_sets: 0,
                    });
                }
            });
        }

        this.setStationAndPodChange(tasks);

        // Remove rest before hydrate
        this.removeRestBeforeHydrate(tasks);

        // Remove last hydration task if it is the last task
        if (tasks[tasks.length - 1].type === "hydrate") {
            tasks.pop();
        }

        // Add ending task
        tasks.push({
            workout_id: workout.id,
            stations: workout.exercises.length,
            current_station: 0,
            pod: 0,
            current_lap: 0,
            remaining_laps: 0,
            type: "ending",
            time: 120,
            current_set: 0,
            remaining_sets: 0,
        });

        this.logger.debug("Exercise tasks:", tasks);
        this.logger.info("Total time for the workout:", DateTimeUtils.formatTime(this.calculateTotalTime(tasks)));
        return tasks;
    }

    /**
     * Sets the station_change and pod_change properties for each task in the given array.
     *
     * @param tasks - An array of ExerciseTask objects.
     * @returns The modified array of ExerciseTask objects with updated station_change and pod_change properties.
     */
    private setStationAndPodChange(tasks: ExerciseTask[]): ExerciseTask[] {
        for (let i = 0; i < tasks.length; i++) {
            if (tasks[i].type === "work" || tasks[i].type === "rest") {
                // Find the next "work" task
                let nextWorkTask = tasks.slice(i + 1).find((task) => task.type === "work");

                if (nextWorkTask) {
                    // Set station_change to true if the next work task is in a different station
                    tasks[i].station_change = nextWorkTask.current_station !== tasks[i].current_station;

                    // Set pod_change to true if the next work task is in a different pod
                    tasks[i].pod_change = nextWorkTask.pod !== tasks[i].pod;
                } else {
                    tasks[i].station_change = false;
                    tasks[i].pod_change = false;
                }
            }
        }
        return tasks;
    }

    /**
     * Removes any "rest" tasks that are followed by a "hydrate" task from the given array.
     *
     * @param tasks - An array of ExerciseTask objects.
     * @returns The modified array of ExerciseTask objects with all "rest" tasks removed that are followed by a "hydrate" task.
     */
    private removeRestBeforeHydrate(tasks: ExerciseTask[]): ExerciseTask[] {
        return tasks.filter((task, index, arr) => {
            // Keep everything that is not of type "rest"
            if (task.type !== "rest") return true;

            // If current type is "rest", check if the next session is "hydrate"
            const nextTask = arr[index + 1];
            if (nextTask && nextTask.type === "hydrate") {
                return false; // Skip this "rest" session
            }

            // Keep the rest type if not followed by hydrate
            return true;
        });
    }

    /**
     * Calculates the total time for a given array of exercise tasks.
     *
     * @param tasks - The array of exercise tasks.
     * @returns The total time calculated from the sum of each task's time.
     */
    private calculateTotalTime(tasks: ExerciseTask[]): number {
        return tasks.reduce((total, currentItem) => total + currentItem.time, 0);
    }
}