import React from "react";
import { useTranslation } from 'react-i18next'; // Import useTranslation
import './Movement.css';

import { MovementScreenData } from "../../types/movement.type";
import { TimerControl } from "../../types/timer.type";

interface HorMovementProps {
  movementScreenData?: MovementScreenData;
  timerControlRef?: React.RefObject<TimerControl | null>;
}

export const HorMovement: React.FC<HorMovementProps> = ({ movementScreenData}): JSX.Element => {

  const { t } = useTranslation(); // Use the hook

  if (!movementScreenData) {
    return (
      <div className="mov-movement-horizontal-wrapper">
        <div className="mov-container-wrapper">
          <div className="mov-container">
            <div className="mov-content">
              <div className="mov-text-wrapper">{t("Loading")}</div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="mov-movement-horizontal-wrapper">
        <div className="mov-container-wrapper">
          <div className="mov-container">
            <div className="mov-content">
              <div className="mov-div">
                <img className="mov-workout-image" alt="Container" src={movementScreenData.workoutImage} />
              </div>
              <div className="mov-div">
                <div className="mov-text">
                  <div className="mov-text-wrapper">{movementScreenData.name}</div>
                  <div className="mov-text-description"> {movementScreenData.description}</div>
                </div>
              </div>
              <div className="mov-div">
                <div className="mov-icon">
                  <img className="mov-img-2" alt="Route" src="/img/route.svg" />
                </div>
                <div className="mov-text">
                  <div className="mov-text-wrapper">{t("Stations")}</div>
                  <div className="mov-text-2">{movementScreenData.stations}</div>
                </div>
              </div>
              <div className="mov-div">
                <img className="mov-img-2" alt="Timer reset" src="/img/goal.svg" />
                <div className="mov-text">
                  <div className="mov-text-wrapper">Pods</div>
                  <div className="mov-text-2">{movementScreenData.pods}</div>
                </div>
              </div>
            </div>
            <div className="mov-movement">
              <div>
                <img src={movementScreenData.movementImage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};