import React from "react";
import "./NextClassStarting.css";
import { Goal } from "./Goal";
import { PersonalTrainer } from "../vertical/PersonalTrainer";
import { ProgressCircle } from "../ProgressCircle";
import { TimerScreenData } from "../../types/screen.type";
import { TimerControl } from "../../types/timer.type";
import { Header } from "../vertical/Header";
import { DateTimeUtils } from "../../utils/datetime.utils";

interface NextClassStartingProps {
  timerScreenData: TimerScreenData;
  timerControlRef?: React.RefObject<TimerControl>;
}

export const NextClassStarting: React.FC<NextClassStartingProps> = ({ timerScreenData, timerControlRef }): React.JSX.Element => {


  return (
    <div className="next-class-starting">
      <div className="container-wrapper">
        <div className="container">
          <div className="content1">
          {(timerScreenData && timerScreenData.header) && (
                <Header
                  icon={timerScreenData.header.icon}
                  title={timerScreenData.header.title} 
                
                />
            )}

            {(timerScreenData && timerScreenData.progressCircle) && (
                <ProgressCircle ref={timerControlRef} initialTotalTime={timerScreenData.progressCircle.initialTotalTime} color={timerScreenData.progressCircle.color} />
            )}     {(timerScreenData && timerScreenData.personalTrainer) && (

                <PersonalTrainer
                  name={timerScreenData.personalTrainer.name}
                  jobTitle={timerScreenData.personalTrainer.job_title}
                  photo={timerScreenData.personalTrainer.photo}
                />
            )}
          </div>
          <div className="logo-workout">
            <div className="overlap">
              <div className="logo1">
                <div className="lgo-wrapper">
                  {timerScreenData && timerScreenData.workoutImage && (
                  <img className="lgo" alt="Lgo" src={timerScreenData.workoutImage} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};