import React from "react";
import "./DaysWrapper.css";

interface DaysWrapperProps {
  hours?: string[]; // Make the hours prop optional
}

const DaysWrapper: React.FC<DaysWrapperProps> = ({ hours = [] }): JSX.Element => {
  return (
    <div className="horCompDaysWrapper">
      <div className="horCompDays3">
        {hours.map((hour, index) => (
          <div
            key={index}
            className={index === 0 ? "horCompDay3" : "horCompDay4"}
          >
            <div className="textWrapper3">{hour.slice(0, 5)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DaysWrapper;