import React, { useEffect, useRef } from "react";
import "./ProgressCircle.css";

interface CircleProgressBarProps {
  progress: number;
  bgColor: string;
  progressColor: string;
}

const CircleProgressBar: React.FC<CircleProgressBarProps> = ({
  progress,
  bgColor,
  progressColor,
}): React.JSX.Element => {

  const radius = 115;
  const circumference = 2 * Math.PI * radius;
  const circleRef = useRef<SVGCircleElement>(null);

  function calculateStrokeDashoffset(circumference: number, progress: number): number {
    const validCircumference = Math.max(circumference, 0);
    const validProgress = Math.min(Math.max(progress, 0), 100);
    const strokeDashoffset = validCircumference - (validProgress / 100) * validCircumference;
    return !Number.isNaN(strokeDashoffset) ? strokeDashoffset : 0;
  }

  const strokeDashoffset = calculateStrokeDashoffset(circumference, progress);

  useEffect(() => {
    if (circleRef.current) {
      // When progress is zero, directly set the strokeDashoffset without transition
      if (progress === 0) {
        circleRef.current.style.transition = "none"; // Disable transition
        circleRef.current.style.strokeDashoffset = `${strokeDashoffset}px`; // Set offset immediately
        circleRef.current.getBoundingClientRect(); // Trigger reflow to apply styles immediately
        // Re-enable transition for subsequent updates
        circleRef.current.style.transition = "stroke-dashoffset 1s linear";
      } else {
        // For other progress values, use the normal transition
        circleRef.current.style.transition = "stroke-dashoffset 1s linear";
        circleRef.current.style.strokeDashoffset = `${strokeDashoffset}px`;
      }
    }
  }, [strokeDashoffset, progress]);

  return (
    <svg
      width="230"
      height="230"
      viewBox="0 0 230 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="115"
        cy="115"
        r={radius}
        fill="none"
        stroke={bgColor}
        strokeWidth="17"
      />
      <circle
        ref={circleRef}
        cx="115"
        cy="115"
        r={radius}
        fill="none"
        stroke={progressColor}
        strokeWidth="17"
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="butt"
        transform="rotate(-90 115 115)"
        style={{ transition: "stroke-dashoffset 1s linear" }}
      />
    </svg>
  );
};

export default CircleProgressBar;