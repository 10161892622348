import log from '../utils/logger.utils';
import { TvType } from '../types/tvs.type';
import { QueryString } from '../utils/querystring.utils';

/**
 * The ConfigService class provides methods for managing configuration settings.
 */
export class ConfigService {

    // #region Members
    apiUrl: string = '';
    language: string = 'en';
    filesStorageUrl: string = '';
    studioId: string = '1';
    tvId: string = '';
    tvInfo: TvType | undefined = undefined;
    classScheduleDate: string = '';
    classScheduleTime: string = '';
    classScheduleId: string = '';
    setDate: string = '';
    setTime: string = '';
    currentTv: TvType | undefined;
    logger: log.Logger;
    exercisesTaskDelay: number = 25000;
    classAboutToStartTime: number = 60000;
    tvDataUpdateInterval: number = 60000;
    mqttUrl: string  = process.env.DOHIIT_MQTT_URL || '';
    mqttUsername: string = process.env.DOHIIT_MQTT_USERNAME || '';
    mqttPassword: string = process.env.DOHIIT_MQTT_PASSWORD || '';
    // #endregion

    // #region Constructor 
    private static instance: ConfigService;

    /**
     * Initializes the ConfigService.
     */
    private constructor() {
        this.logger = log.getLogger('ConfigService');

        // Initialize configuration values


        try {
            this.initializeConfig();
            this.setFilesStorageUrl('https://dohiit-prd.s3.eu-west-3.amazonaws.com/'); // Set the files storage URL
    
            this.getTvInfo().then((tvInfo) => {
                this.currentTv = tvInfo;
            });

        }   catch (error) {
            this.logger.error(error);
        }




        this.logger.info('Initialized');
        this.logger.debug('Config:', this);
    }

    /**
     * Initializes the configuration based on environment and query string values.
     */
    private initializeConfig(): void {
        const apiUrl = process.env.DOHIIT_API_URL;
        let tvId = process.env.DOHIIT_TV_ID;

        // Overwrite `tvId` if `tv` parameter exists in query string
        const tvIdFromQuery = QueryString.getTvId();
        if (tvIdFromQuery) {
            tvId = tvIdFromQuery;
        }

        // Perform sanity checks
        if (!apiUrl) throw new Error('API URL not provided');
        //if (!tvId) throw new Error('TV ID not provided');


        this.apiUrl = apiUrl;
        this.tvId = tvId ? tvId : '';

        this.studioId = process.env.DOHIIT_STUDIO_ID || '1';

        // Load optional environment variables
        this.exercisesTaskDelay = Number(process.env.DOHIIT_EXERCISES_TASK_DELAY) || 25000;
        this.classAboutToStartTime = Number(process.env.DOHIIT_EXERCISES_CLASS_ABOUT_TO_START_TIME) || 60000;
        this.tvDataUpdateInterval = Number(process.env.DOHIIT_TV_DATA_UPDATE_INTERVAL) || 60000;
        this.mqttUrl = process.env.DOHIIT_MQTT_URL || '';
        this.mqttUsername = process.env.DOHIIT_MQTT_USERNAME || '';
        this.mqttPassword = process.env.DOHIIT_MQTT_PASSWORD || '';

        // Load optional query params
        this.classScheduleDate = QueryString.getClassScheduleDate() || '';
        this.classScheduleTime = QueryString.getClassScheduleTime() || '';
        this.classScheduleId = QueryString.getClassScheduleId() || '';
        this.setDate = QueryString.getSetDate() || '';
        this.setTime = QueryString.getSetTime() || '';
    }

    /**
     * Returns an instance of the ConfigService class.
     * If an instance does not exist, a new instance is created and returned.
     *
     * @returns {ConfigService} The ConfigService instance.
     */
    public static getInstance(): ConfigService {
        if (!ConfigService.instance) {
            ConfigService.instance = new ConfigService();
        }
        return ConfigService.instance;
    }

    // #endregion

    // #region Setters and Getters
    setApiUrl(apiUrl: string) {
        this.apiUrl = apiUrl;
    }
    getApiUrl() {
        return this.apiUrl;
    }

    setLanguage(language: string) {
        this.language = language;
    }
    getLanguage() {
        return this.language;
    }

    setFilesStorageUrl(filesStorageUrl: string) {
        this.filesStorageUrl = filesStorageUrl;
    }
    getFilesStorageUrl() {
        return this.filesStorageUrl;
    }

    setTvId(tvId: string) {
        this.tvId = tvId;
    }
    getTvId() {
        return this.tvId;
    }

    setTvInfo(tvInfo: TvType) {
        this.tvInfo = tvInfo;
    }

    // #endregion

    // #region Private Methods

    /**
     * Fetches TV information from the API.
     * 
     * @returns A promise that resolves to a TvType object or undefined in case of failure.
     */
    private async fetchTvInfo(): Promise<TvType | undefined> {
        try {
            const response = await fetch(`${this.apiUrl}/tvs`);
            if (!response.ok) throw new Error('Failed to fetch TVs');

            const tvsData = await response.json();
            if (!tvsData) throw new Error('No TVs found');

            return this.getTvById(tvsData);
        } catch (error) {
            this.logger.error(error);
            return undefined;
        }
    }

    /**
     * Retrieves a TV by its ID from the given array of TVs.
     * 
     * @param tvs - The array of TVs to search in.
     * @returns The TV object with the matching ID, or undefined if not found.
     */
    private getTvById(tvs: TvType[]): TvType | undefined {
        const tv = tvs.find(tv => tv.id === Number(this.tvId));
        if (!tv) {
            this.logger.error('TV not found');
            return undefined;
        }
        return tv;
    }

    // #endregion

    // #region Public Methods

    /**
     * Retrieves the TV information.
     * 
     * @returns A promise that resolves to a TvType object or undefined if the information is not available.
     */
    public async getTvInfo(): Promise<TvType | undefined> {
        if (!this.tvInfo && this.apiUrl && this.tvId) {
            const tvInfo = await this.fetchTvInfo();
            if (tvInfo) {
                this.setTvInfo(tvInfo);
            }
            return tvInfo;
        }
        return this.tvInfo;
    }

    // #endregion
}