import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DateTimeUtils } from "../../utils/datetime.utils";
import SoundPlayer from "../../components/vertical/SoundPlayer/SoundPlayer";
import { WorkoutScreenData } from "../../types/screen.type";
import { ProgressBar } from "../../components/vertical/ProgressBar";
import { Videos } from "../../components/vertical/Video";
import { Header } from "../../components/vertical/Header";
import { Hydrate } from "../../components/vertical/Hydrate";
import { ChangeStation } from "../../components/vertical/ChangeStation";
import "./Exercise.css";
import { Loading, ProgressCircle, Sets, Stay, PersonalTrainer } from "../../components";
import { Notification2 } from "../../components/vertical/Notification";
import { TimerControl } from "../../types/timer.type";
import { Finish } from "../../components/vertical/Finish";



interface ExerciseProps {
  screenData?: WorkoutScreenData;
  timerControlRef?: React.RefObject<TimerControl>;
  onLoad?: () => void;
}

export const Exercise: React.FC<ExerciseProps> = ({ screenData, timerControlRef, onLoad }): React.JSX.Element => {
  const { t } = useTranslation();

  useEffect(() => {
    if (onLoad) {
      onLoad(); // Notify that this component has finished loading
    }
  }, [onLoad]);


  if (!screenData) {
    return (
      <div className="workout">
        <div className="container-wrapper">
          <div className="container">
            <Loading message="Loading..." />
          </div>
        </div>
      </div>
    );
  } else {

    return (
      <div className="workout">
        <div className="container-wrapper">
          <div className="container">
            {(screenData && screenData.header) && (
              <div style={{ display: screenData.header.show ? 'block' : 'none' }}>
                <Header
                  icon={screenData.header.icon}
                  title={screenData.header.title} // Use translated workout title
                  date={DateTimeUtils.formatDateToPortuguese(screenData.header.date)}
                  time={screenData.header.time} // Use formatted workout time
                />
              </div>
            )}
            {(screenData && screenData.progressCircle) && (
              <div style={{ display: screenData.progressCircle.show ? 'block' : 'none' }}>
                <ProgressCircle ref={timerControlRef} initialTotalTime={screenData.progressCircle.initialTotalTime} color={screenData.progressCircle.color} bypassCacheGuid={screenData.progressCircle.bypassCacheGuid} />
              </div>
            )}
            {(screenData && screenData.progressBar) && (
              <div style={{ display: screenData.progressBar.show ? 'block' : 'none' }}>
                <ProgressBar
                  total={screenData.progressBar.numberOfElements}
                  progress={screenData.progressBar.progress}
                  label={screenData.progressBar.label}
                  time={screenData.progressBar.time}
                  hideOnTimeLeft={screenData.progressBar.hideOnTimeLeft}
                  bypassCacheGuid={Date.now().toString()} />
              </div>
            )}
            {(screenData && screenData.notification) && (

              <div style={{ display: screenData.notification.show ? 'block' : 'none' }}>
                <Notification2 message={screenData.notification.message} time={screenData.notification.time} showOnTimeLeft={screenData.notification.showOnTimeLeft} bypassCacheGuid={Date.now().toString()} />
              </div>

            )}
            {(screenData && screenData.sets) && (
              <div style={{ display: screenData.sets.show ? 'block' : 'none' }}>
                <Sets total={screenData.sets.total} active={screenData.sets.active} />
              </div>

            )}
            {(screenData && screenData.stay) && (
              <Stay message={screenData.stay.message} />
            )}
            {(screenData && screenData.changeStation) && (
              <ChangeStation message={screenData.changeStation.message} />
            )}

            {(screenData && screenData.videos) && (
              <div style={{ display: screenData.videos.show ? 'block' : 'none' }}>
                <Videos exerciseStationVideos={screenData.videos.exercisesStation} showStations={screenData.videos.showStations} />
              </div>
            )}

            {(screenData && screenData.batteryMedium && screenData.batteryMedium.show) && (

              <div style={{ display: screenData.batteryMedium.show ? 'block' : 'none' }}>
                <Hydrate message={screenData.batteryMedium.message} />
              </div>

            )}

            {(screenData && screenData.finish && screenData.finish.show) && (

              <div style={{ display: screenData.finish.show ? 'block' : 'none' }}>
                <Finish message={screenData.finish.message} />
              </div>

            )}
            {(screenData && screenData.personalTrainer && screenData.personalTrainer.show) && (

              <div style={{ display: screenData.personalTrainer.show ? 'block' : 'none' }}>
                <PersonalTrainer
                  name={screenData.personalTrainer.name}
                  jobTitle={screenData.personalTrainer.job_title}
                  photo={screenData.personalTrainer.photo}
                />
              </div>
            )}
            <SoundPlayer soundFile="/sound/notification_alert_ping_bright.mp3" play={false} />
          </div>
        </div>
      </div>
    );
  }
};
