// DataFetchService.ts

import { BookingClasses } from '../types/booking.type';
import { ClassData } from '../types/class.type';
import { TvTypeEnum } from '../types/tvs.type';
import log from '../utils/logger.utils';
import { ApiService } from './api.service';
import { ConfigService } from './config.service';

export class DataFetchService {
    private logger: log.Logger;
    private apiService: ApiService;
    private config: ConfigService;
    private classData?: ClassData;
    private bookingData?: BookingClasses;
    private movementData?: ClassData;

    constructor(apiService: ApiService, config: ConfigService) {
        this.logger = log.getLogger('DataFetchService');
        this.apiService = apiService;
        this.config = config;
    }

    public async startFetching() {
        const tv = this.config.currentTv;
        if (!tv) throw new Error('TV configuration not found');

        this.logger.info(`Starting data fetching for TV type ${tv.type}.`);

        switch (tv.type) {
            case TvTypeEnum.Timer:
            case TvTypeEnum.Exercise:
                await this.refreshClassData();
                setInterval(() => this.refreshClassData(), 60 * 1000);
                break;
            case TvTypeEnum.Schedule:
                await this.refreshBookingData();
                setInterval(() => this.refreshBookingData(), 60 * 1000);
                break;
            case TvTypeEnum.Movement:
                await this.refreshMovementData();
                setInterval(() => this.refreshMovementData(), 60 * 1000);
                break;
            default:
                this.logger.error('Invalid TV type:', tv.type);
                throw new Error('Invalid TV type');
        }
    }

    public async refreshClassData(): Promise<ClassData | undefined> {
        try {
            const date = this.config.classScheduleDate;
            const newClassData = await this.apiService.fetchClassData(date);
            //if (JSON.stringify(newClassData) !== JSON.stringify(this.classData)) {
                this.classData = newClassData;
                this.logger.trace('Class data has been updated:', this.classData);
                return this.classData;
            //} else {
            //    this.logger.debug('Class data unchanged; no update needed.');
            //    return undefined;
            //}
        } catch (error) {
            this.logger.error('Error fetching class data:', error);
            throw error;
        }
    }

    public async refreshBookingData(): Promise<BookingClasses | undefined> {
        try {
            const newBookingData = await this.apiService.fetchBookingData();
            //if (JSON.stringify(newBookingData) !== JSON.stringify(this.bookingData)) {
                this.bookingData = newBookingData;
                this.logger.debug('Booking data has been updated:', this.bookingData);
                return this.bookingData;
            //} else {
            //    this.logger.trace('Booking data unchanged; no update needed.');
            //    return undefined;
            //}
        } catch (error) {
            this.logger.error('Error fetching booking data:', error);
            throw error;
        }
    }

    public async refreshMovementData(): Promise<ClassData | undefined> {
        try {
            const newMovementData = await this.apiService.fetchMovementData();
            //if (JSON.stringify(newMovementData) !== JSON.stringify(this.movementData)) {
                this.movementData = newMovementData;
                this.logger.debug('Movement data has been updated:', this.movementData);
                return this.movementData;
            //} else {
            //    this.logger.trace('Movement data unchanged; no update needed.');
            //    return undefined;
            //}
        } catch (error) {
            this.logger.error('Error fetching movement data:', error);
            throw error;
        }
    }

    public getClassData(): ClassData | undefined {
        return this.classData;
    }

    public getBookingData(): BookingClasses | undefined {
        return this.bookingData;
    }

    public getMovementData(): ClassData | undefined {
        return this.movementData;
    }
}