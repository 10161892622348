import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

// Find the root element in your HTML
const rootElement = document.getElementById('app');

// Create the root and render your App
const root = createRoot(rootElement!);

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

