export type TvType = {
    id: number;
    number?: number;
    name: string;
    type: TvTypeEnum;
    description?: string;
    orientation?: string;
    created_at?: Date;
    updated_at?: Date;
}

export enum TvTypeEnum {
    Exercise = 'exercise',
    Timer = 'timer',
    Schedule = 'schedule',
    Movement = 'movement',
    Billboard = 'billboard',
}



