import React from "react";
import { useTranslation } from "react-i18next";
import { DateTimeUtils } from "../../utils/datetime.utils";
import SoundPlayer from "../../components/vertical/SoundPlayer/SoundPlayer";
import { ProgressBar } from "../../components/vertical/ProgressBar";
import { Videos } from "../../components/vertical/Video";
import { Header } from "../../components/vertical/Header";
import { Hydrate } from "../../components/vertical/Hydrate";
import { ChangeStation } from "../../components/vertical/ChangeStation";
import { Loading, ProgressCircle, Sets, Stay, PersonalTrainer } from "../../components";
import { Notification2 } from "../../components/vertical/Notification";
import { TimerControl } from "../../types/timer.type";
import { NextClassStarting } from "../../components/NextClassStarting/NextClassStarting";
import { TimerScreenData } from "../../types/screen.type";


interface TimerProps {
  timerScreenData?: TimerScreenData;
  timerControlRef?: React.RefObject<TimerControl>;
  onLoad?: () => void;
}

export const Timer: React.FC<TimerProps> = ({ timerScreenData, timerControlRef, onLoad }): React.JSX.Element => {
  const { t } = useTranslation();


  if (!timerScreenData) {
    return (
      <div className="next-class-starting">
        <div className="container-wrapper">
          <div className="container">
            <Loading message="Loading..." />
          </div>
        </div>
      </div>
    );
  } else if (!timerScreenData.isWorkout) {
    return (
        <NextClassStarting timerScreenData={timerScreenData} timerControlRef={timerControlRef} />

      );
  } else {

    return (
      <div className="next-class-starting">
        <div className="container-wrapper">
          <div className="container">
            {(timerScreenData && timerScreenData.header) && (
              <div style={{ display: timerScreenData.header.show ? 'block' : 'none' }}>
                <Header
                  icon={timerScreenData.header.icon}
                  title={timerScreenData.header.title} // Use translated workout title
                  date={DateTimeUtils.formatDateToPortuguese(timerScreenData.header.date)}
                  time={timerScreenData.header.time} // Use formatted workout time
                />
              </div>
            )}
            {(timerScreenData && timerScreenData.progressCircle) && (
              <div style={{ display: timerScreenData.progressCircle.show ? 'block' : 'none' }}>
                <ProgressCircle ref={timerControlRef} initialTotalTime={timerScreenData.progressCircle.initialTotalTime} color={timerScreenData.progressCircle.color} />
              </div>
            )}
            {(timerScreenData && timerScreenData.progressBar) && (
              <div style={{ display: timerScreenData.progressBar.show ? 'block' : 'none' }}>
                <ProgressBar
                  total={timerScreenData.progressBar.numberOfElements}
                  progress={timerScreenData.progressBar.progress}
                  label={timerScreenData.progressBar.label} />
              </div>
            )}
            {(timerScreenData && timerScreenData.notification) && (

              <div style={{ display: timerScreenData.notification.show ? 'block' : 'none' }}>
                <Notification2 message={timerScreenData.notification.message} />
              </div>

            )}
            {(timerScreenData && timerScreenData.sets) && (
              <div style={{ display: timerScreenData.sets.show ? 'block' : 'none' }}>
                <Sets total={timerScreenData.sets.total} active={timerScreenData.sets.active} />
              </div>

            )}
            {(timerScreenData && timerScreenData.stay) && (
              <Stay message={timerScreenData.stay.message} />
            )}
            {(timerScreenData && timerScreenData.changeStation) && (
              <ChangeStation message={timerScreenData.changeStation.message} />
            )}

            {(timerScreenData && timerScreenData.videos) && (
              <div style={{ display: timerScreenData.videos.show ? 'block' : 'none' }}>
                <Videos exerciseStationVideos={timerScreenData.videos.exercisesStation} showStations={timerScreenData.videos.showStations} />
              </div>
            )}

            {(timerScreenData && timerScreenData.batteryMedium && timerScreenData.batteryMedium.show) && (

              <div style={{ display: timerScreenData.batteryMedium.show ? 'block' : 'none' }}>
                <Hydrate message={timerScreenData.batteryMedium.message} />
              </div>

            )}
            {(timerScreenData && timerScreenData.personalTrainer && timerScreenData.personalTrainer.show) && (

              <div style={{ display: timerScreenData.personalTrainer.show ? 'block' : 'none' }}>
                <PersonalTrainer
                  name={timerScreenData.personalTrainer.name}
                  jobTitle={timerScreenData.personalTrainer.job_title}
                  photo={timerScreenData.personalTrainer.photo}
                />
              </div>
            )}
            <SoundPlayer soundFile="/sound/notification_alert_ping_bright.mp3" play={false} />
          </div>
        </div>
      </div>
    );

  }
};
