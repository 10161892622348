import React from "react";
import "./PersonalTrainer.css";

interface PersonalTrainerProps {
  photo?: string;
  name: string;
  jobTitle: string;
}

const PersonalTrainer: React.FC<PersonalTrainerProps> = ({ photo, name, jobTitle }): React.JSX.Element => {

  return (
    <div className="PT-container">
      ({photo &&
        <div className="icon">
          <img className="rectangle" alt="Profile" src={photo} />
        </div>
      })
      <div className="text">
        <div className="text-wrapper">{name}</div>
        <div className="text-wrapper">|</div>
        <div className="text-wrapper">{jobTitle}</div>
      </div>
    </div>
  );
};

export default PersonalTrainer;