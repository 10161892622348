export interface TimerControl {
  play: () => void;
  stop: () => void;
  resume: () => void;
  reset: () => void;
  setTime: (newTime: number) => void;
}


export enum TimerControlAction {
  PLAY = 'play',
  STOP = 'stop',
  RESUME = 'resume',
  RESET = 'reset',
  SET_TOTAL_TIME = 'setTotalTime',
}