import React, { useEffect } from "react";
import "./Videos.css";

type VideoProps = {
    url?: string;
    onLoad?: () => void;
};

const Video: React.FC<VideoProps> = ({ url, onLoad }: VideoProps): React.JSX.Element => {

    useEffect(() => {
        if (onLoad) {
            onLoad(); // Notify that this component has finished loading
        }
    }, [onLoad]);

    return (
        <video width="100%" autoPlay loop muted controls={false}>
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>


    );
};

export default Video;