import log from 'loglevel';
import prefix from 'loglevel-plugin-prefix';

/**
 * Registers the `loglevel-plugin-prefix` with the `loglevel` logger.
 * The plugin allows for adding a prefix (such as timestamps, log levels, etc.) to log messages.
 */
prefix.reg(log);

/**
 * Configures the logging prefix to include:
 * - A timestamp
 * - The log level (in uppercase)
 * - The logger name (defaults to 'global' if not provided)
 */
prefix.apply(log, {
  template: '[%t] %l (%n):',
  timestampFormatter: (date) => date.toISOString(),
  levelFormatter: (level) => level.toUpperCase(),
  nameFormatter: (name) => name || 'global',
});

/**
 * Sets the logging level based on the `DOHIIT_LOG_LEVEL` environment variable.
 * If the variable is not set, defaults to 'info'.
 * 
 * The logging levels are: 'trace', 'debug', 'info', 'warn', 'error', 'silent'.
 */
const logLevel: string = process.env.DOHIIT_LOG_LEVEL || 'info';
log.setLevel(logLevel as log.LogLevelDesc);

/**
 * Exports the configured logger to be used across the application.
 */
export default log;