import React from "react";
import "./goal.css";

interface Props {
  className: any;
}

export const Goal = ({ className }: Props): JSX.Element => {
  return <div className={`goal ${className}`} />;
};
