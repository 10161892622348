import React from "react";
import "./Sets.css";

interface SetsProps {
  total: number; // Total number of timer-reset elements (<=10)
  active: number; // Number of timer-reset elements to show in red
}

const Sets: React.FC<SetsProps> = ({ total, active }: SetsProps): React.JSX.Element => {

  const timerResets = Array.from({ length: total }, (_, index) => {
    const isRed = index < active;
    const color = isRed ? "#E96264" : "#FCFCFC";
    return (
      <img
        key={index}
        className="timer-reset"
        alt="Timer reset"
        src={`/img/timer-reset.svg#${color}`}
        style={{ filter: isRed ? 'none' : 'brightness(0) invert(1)' }} // Adjust color for non-red images
      />
    );
  });

  return <div className="sets">{timerResets}</div>;
};

export default Sets;