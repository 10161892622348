import React from "react";
import "./Header.css";
import { Day } from "../../Days";

interface HeaderProps {
  icon: string;
  title: string;
  date?: string;
  time?: string;
}
const Header: React.FC<HeaderProps> = ({
  icon,
  title,
  date,
  time,
}: HeaderProps): React.JSX.Element => {

  return (
    <div className="header">
      <div className="title">
        <img className="dumbbell1" alt="Workout Icon" src={icon} />
        <div className="text">{title}</div>
      </div>
      {(date && time) && (
        <Day dateLabel={date} time={time} />
      )}
    </div>
  );
};

export default Header;
