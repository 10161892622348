import { BookingClassesWrapper, BookingData } from "./booking.type";
import { ClassSchedule } from "./class.type";
import { ExerciseTask } from "./exercise.type";

export enum RenderItemType {
    BOOKING = 'BOOKING',
    EXERCISE = 'EXERCISE',
    EXERCISE_NEXT_WORKOUT = 'EXERCISE_NEXT_WORKOUT',
    EXERCISE_ABOUT_TO_START = 'EXERCISE_ABOUT_TO_START',
    MOVEMENT = 'MOVEMENT',
    TIMER = 'TIMER',
    TIMER_NEXT_WORKOUT = 'TIMER_NEXT_WORKOUT',
}
 
interface RenderItemInfo {
    exerciseTask?: ExerciseTask;
    duration?: number;
    bookingClassesWrapper? : BookingClassesWrapper;
}

export interface RenderItem {
    renderItemType: RenderItemType;
    renderItemInfo?: RenderItemInfo;
    classSchedule?: ClassSchedule;
    bookingData?: BookingData
    date?: string;
    time?: string;
    duration?: number;
}
