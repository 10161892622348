import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en/translation.json';
import ptTranslation from './pt/translation.json';

// Function to get the initial language setting
const getInitialLanguage = () => {
  // Check for a saved language in local storage or use the browser's default language
  const savedLanguage = localStorage.getItem('language');
  const browserLanguage = navigator.language.split('-')[0]; // e.g., 'en' from 'en-US'

  // Default to saved language, then browser language, then fallback to English
  return savedLanguage || browserLanguage || 'en';
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      pt: {
        translation: ptTranslation,
      },
    },
    lng: getInitialLanguage(), // Set initial language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;