import React from "react";
import "./ChangeStation.module.css";

interface ChangeStationProps {
  message?: string;
}

const ChangeStation: React.FC<ChangeStationProps> = ({ message = "text" }: ChangeStationProps): React.JSX.Element => {

  return (
    <div className="gif">
      <img src="/img/movement_350x350.gif" alt="Movement GIF" className="movement-image" />
      <div className="text">{message}</div>
    </div>
  );
};

export default ChangeStation;