import { Booking, BookingClasses, BookingData, BookingFiltered } from '../types/booking.type';

export class BookingService {

    static toBookingData(data: BookingClasses): BookingData[] {
        if (!data) {
            return [];
        }
        return data.map((entry) => {
            const firstWorkoutImage = entry.class_schedules[0]?.workout?.image || null;
            const startTimes = entry.class_schedules.map(schedule => schedule.start_time);

            return {
                date: entry.date,
                class_start_times: startTimes,
                image: firstWorkoutImage
            };
        });
    }

    // Method to filter class times into morning, afternoon, and night periods
    static filterClassTimesByPeriod(schedules: BookingData[]): BookingFiltered[] {
        return schedules.map(schedule => {
            const morning: string[] = [];
            const afternoon: string[] = [];
            const night: string[] = [];

            schedule.class_start_times.forEach(time => {
                const [hours, minutes] = time.split(':').map(Number);
                const totalMinutes = hours * 60 + minutes;

                if (totalMinutes >= 6 * 60 && totalMinutes < 12 * 60) {
                    morning.push(time);
                } else if (totalMinutes >= 12 * 60 && totalMinutes < 18 * 60) {
                    afternoon.push(time);
                } else {
                    night.push(time);
                }
            });

            // Sorting the time arrays
            const sortTimes = (times: string[]) => {
                return times.sort((a, b) => {
                    const [aHours, aMinutes] = a.split(':').map(Number);
                    const [bHours, bMinutes] = b.split(':').map(Number);
                    return (aHours * 60 + aMinutes) - (bHours * 60 + bMinutes);
                });
            };

            return {
                date: schedule.date,
                morning: sortTimes(morning),
                afternoon: sortTimes(afternoon),
                night: sortTimes(night),
                image: schedule.image
            };
        });
    }

    // Method to transform filtered schedule data into the final schedule format
    static transformScheduleData(data: BookingFiltered[]): Booking {
        const daysCount = 7;
        const result: Booking = {
            morning: [],
            afternoon: [],
            night: []
        };
        if (data.length === 0) {
            return result;
        }
        // Initialize arrays with empty strings for each time slot
        for (let i = 0; i < daysCount; i++) {
            const day = data[i];

            // Ensure each time slot is an array of the same length
            for (let j = 0; j < Math.max(day.morning.length, 1); j++) {
                if (!result.morning[j]) {
                    result.morning[j] = Array(daysCount).fill("");
                }
                result.morning[j][i] = day.morning[j] || "";
            }

            for (let j = 0; j < Math.max(day.afternoon.length, 1); j++) {
                if (!result.afternoon[j]) {
                    result.afternoon[j] = Array(daysCount).fill("");
                }
                result.afternoon[j][i] = day.afternoon[j] || "";
            }

            for (let j = 0; j < Math.max(day.night.length, 1); j++) {
                if (!result.night[j]) {
                    result.night[j] = Array(daysCount).fill("");
                }
                result.night[j][i] = day.night[j] || "";
            }
        }

        // Ensure the sum of elements is always 6 by adding empty blocks
        const emptyBlock: (string | null)[] = new Array(7).fill(null);

        while (result.morning.length + result.afternoon.length + result.night.length < 6) {
            if (result.morning.length < 2) {
                result.morning.push(emptyBlock);
            } else if (result.afternoon.length < 2) {
                result.afternoon.push(emptyBlock);
            } else {
                result.night.push(emptyBlock);
            }
        }

        return result;
    }
}

